<template>
  <b-card>
    <g-table
      ref="messages-table"
      :items="items"
      :columns="tableColumns"
      :no-action="true"
      per-page="25"
      :total-rows="totalRows"
      :createButton="{ visiable: true , permission: 'addMessages'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push(
            { name: 'messages-new' }
          );
        }
      "
    >

      <template #actions="{ item }">
        <div class="text-nowrap">

          <!-- Send Message button -->
          <b-button
            v-if="!item.isSent"
            v-b-tooltip.hover.top="$t('sendMessage')"
            v-permission="'sendMessages'"
            variant="flat-warning"
            class="btn-icon"
            size="sm"
            @click="sendMessage(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="SendIcon"
              stroke="orange"
            />
          </b-button>

          <!-- print button -->
          <b-button
            v-b-tooltip.hover.top="$t('print')"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="print(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="PrinterIcon"
              stroke="green"
            />
          </b-button>

          <!-- edit button -->
          <b-button
            v-b-tooltip.hover.top="item.isSent ? $t('preview') : $t('edit')"
            v-permission="'editMessages'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              :icon="item.isSent ? 'EyeIcon' : 'EditIcon'"
              class="mx-25 clickable"
            />
          </b-button>

          <!-- delete button -->
          <b-button
            v-if="!item.isSent"
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteMessages'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
    components: {
    GTable,
    },
    mixins: [reportMixin],
    data() {
    return {
        items: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        rows: rows,
        modal: false,
    };
    },
    computed: {
    tableColumns() {
        return [
        { key: 'messageNumber', label: this.$t('messageNumber'), sortable: true },
        { key: 'messageDate', label: this.$t('messageDate'), sortable: true },
        { key: 'groupNameAr', label: this.$t('groupName'), sortable: true },
        { key: 'groupValueNameAr', label: this.$t('groupValueName'), sortable: true },
        { key: 'messageTitle', label: this.$t('messageTitle'), sortable: true },
        { key: 'studentsRecievedMessageCount', label: this.$t('studentsReciedvedMessageCount') },
        {
          key: 'status',
          field: 'status',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.isSent) {
              return `<span class="badge bg-light-success bg-darken-1 p-75"> ${this.$t(
                'sent'
              )}</span>`;

            // otherwise
            }
            return `<span class="badge bg-light-danger bg-darken-1 p-75"> ${this.$t(
                'notSent'
              )}</span>`;
          },
        },
        { key: 'actions' },
        ];
    },
    },
    mounted() {
    this.getItems();
    },

    methods: {
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },

    getItems() {
        this.get({ url: 'Messages' }).then((data) => {
        this.items = data.pageData;
        this.items.forEach(element => {
            element.messageDate = this.getDate(element.messageDate);
        });
        });
    },

    remove(item) {
        this.confirmAction(
        {
            text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
            this.delete({ url: 'Messages', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
            });
        }
        );
    },

    edit(item) {
        this.$router.push({
        name: 'messages-edit',
        params: { id: item.id },
        });
    },

    print(item) {
        const reportName = this.isRight ? 'Messages-ar' : 'Messages-en';
        const printedItem = {
        id: item.id,
        }
        this.printReport(reportName, printedItem);
    },

    sendMessage(item) {
      if (item.studentsRecievedMessageCount === 0) {
        this.doneAlert({ text: this.$t('noStudentsSelectedToSendMessage'), type: 'info' })
        return;
      }
      this.get({ url: `Messages/sendSMS/${item.id}` }).then(() => {
        this.doneAlert({ text: this.$t('sendSuccessfully') });
        item.isSent = true;
      });
    }
    },
};
</script>

<style></style>
